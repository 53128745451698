.legalPage {
  max-width: 1000px;
  padding: 1rem;
  margin: auto auto;
  p {
    padding: 1rem 0;
    line-height: 1.4rem;
  }

  h3 {
    padding-top: 2rem;
  }

  h2 {
    text-transform: uppercase;
    padding: 1rem 0;
  }

  h4 {
    padding-top: 1rem;
  }

  ul {
    li {
        padding: .5rem 0;
    }
  }
}

.legalSection {
    ul {
        list-style: disc;
        padding-left: 1.5rem;
    }
}

.legalAddress {
    font-weight: 500;
    ul {
        list-style: none;
        padding: 0;
        li {
            padding: .1rem 0;
        }
    }
}