@import 'cssreset.scss';
@import 'colors.scss';

a {
  text-decoration: none;
  cursor: pointer;
}

.App-logo {
  padding: 1rem 2rem;
  height: 50px;
  pointer-events: none;
}

header {
  display: flex;
  align-items: center;
  .MainMenu {
    flex: 1 1;
    ul {
      align-items: center;
      list-style: none;
      padding: 0 0 0 3rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      li {
        a {
          color: $black;
          &:hover {
            color: darken($black, 20%),
          }
        }
      }
    }
  }
  .Actions {
    flex: 0 0  320px;
    button {
      width: 140px;
      padding: 0.7rem 1rem;
      margin: 0.5rem 0.5rem 0.5rem 0;
      border-radius: 5px;
      border: 0;
      font-size: 0.8rem;
      font-weight: 600;
      background-color: $green;
      color: $white;
      cursor: pointer;
      &:hover {
        background-color: darken($green, 10%);
      }
    }
  }
  button.demoButton {
    background-color: $yellow;
    color: $black;
    &:hover {
      background-color: darken($yellow, 10%);
    }
  }
}

.GradientSection {
  background-image: radial-gradient(farthest-corner at 80vw 100vw,
      lighten($onepurple, 40%) 0%, $onepurple 100%);
  min-height: 200px;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: $white;
  .Content {
      color: #fff;
  }
}

.WhiteSection {
  min-height: 200px;
  background-color: $white;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.noPadding {
  padding: 0;
}

.Content {
  padding: 3rem 0 4rem 0;
  max-width: 1440px;
  width: 100%;
  text-align: left;
  color: $black;
  h1 {
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 2rem;
    text-align: center;
    &.hasSubHeading {
      padding-bottom: 1rem;
    }
  }
  h2 {
    text-align: center;
    line-height: 1rem;
    font-weight: 500;
    padding-bottom: 1rem;
  }
  ul.ContentList {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    li {
      flex: 1 1;
      position: relative;
      min-width: 200px;
      text-align: center;
    }
    i {
      width: 100%;
      text-align: center;
      display: block;
    }
    h3, p {
      text-align: center;
    }
    h3 {
      font-size: 2rem;
      font-weight: 600;
      padding: 1rem;
    }
    p {
      font-size: 1.1rem;
      line-height: 1.6rem;
      padding-bottom: 1rem;
    }
    img {
      width: 200px;
      border-radius: 50px;
    }
  }
  .SideImage {
    display: flex;
    flex-direction: row;
    .ImageSide {
      flex: 1 0 300px; 
      text-align: center;
      svg {
        max-width: 400px;
      }
    }
    .TextSide {
      flex: 2 1;
      p {
        line-height: 1.4em;
        padding-bottom: 1rem;
      }
      h2 {
        font-weight: 600;
      }
    }
  }
}

.OurMission {
  padding-top: 100px;
  h1, h2 {
    text-align: left;
  }
  h1 {
    font-size: 2.5em;
  }
}

.HomeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Home header {
  display: flex;
  width: 100%;
  max-width: 1440px;
}

.TopStrip {
  padding: 0.8rem 1.5rem;
  text-align:right;
  height: 20px;
  width: 100%;
  box-sizing: border-box;
  color: $white;
  .LanguageSelect {
    display: none;
  }
}

.Banner {
  box-sizing: border-box;
  display: flex;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 10vh 0;
  color: $black;
  .Banner__slogan {
    justify-content: center;
    align-items: right;
    flex: 0 0 600px;
    display: flex;
    flex-direction: column;
    button {
      width: 220px;
      margin: 1rem 2rem;
      border-radius: 5px;
      border: 0;
      padding: 15px 10px 15px 10px;
      background-color: $sky;
      color: #fff;
      font-size: 1rem;
      cursor: pointer;
      &:hover {
        background-color: darken($sky, 10%);
      }
    }
    h1, b, i {
      margin: 0rem 2rem;
      font-style: normal;
    }
    h1 {
      font-weight: 700;
      font-size: 54px;
      padding-bottom: 1rem;
    }
    i {
      padding-bottom: 1rem;
      font-size: 1.2rem;
      opacity: 0.7;
    }
    b {
      text-align: left;
      font-size: 23px;
      font-weight: 400;
      line-height: 2rem;
    }
  }
  .Banner__image {
    flex: 1 0;
    text-align: center;
    img {
      width: 80%;
    }
  }
}

.Banner__footer {
  width: 100%;
  max-width: 1440px;
  padding-bottom: 2rem;
  color: $black;
  opacity: 0.6;
  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0;
      list-style: none;
      b {
        font-weight: 400;
        font-size: 1.3rem;
        margin-left: 20px;
      }

    }
  }
}

.Registration {
  .TopButton {
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    box-sizing: border-box;
    text-align: center;
    padding: 0.5rem 0;
    background-color: $teal;
    color: $navy;
    cursor: pointer;
    &:hover {
      background-color: darken($teal, 10%);
    }
    border: 0;
    border-radius: 5px 5px 0 0;
    font-weight: 600;
  }

  form {
    padding: 3rem 0 4rem 2rem;
    max-width: 1440px;
    width: 100%;
    text-align: left;
    color: #fff;
    display: flex;
    flex-direction: column;
    h1 {
      font-weight: 300;
      padding: 1rem 0;
      margin: 0;
      font-size: 2rem;
    }
    em {
      padding: 0;
      transition: 1s all;
      height: 0;
      line-height: 0;
      overflow: hidden;
      opacity: 0;
      max-width: 400px;
      font-style: normal;
      &.shown {
        padding: 1rem 0;
        height: auto;
        line-height: normal;
        opacity: 1;
      }
      a {
        color: $white;
        &:hover {
          color: darken($white, 20%);
        }
      }
    }
    input {
      padding: 1.1rem;
      width: 400px;
      max-width: 80vw;
      margin: 0.5rem 0.5rem 0.5rem 0;
      border-radius: 5px;
      border: 0;
      font-size: 1.2rem;
      background-color: lighten($onepurple, 70%);
      color: lighten($onepurple, 20%);
      border: 2px solid $onepurple;
      outline: transparent;
      &:focus {
        background-color: $white;
        border: 2px solid $sky;
      }
    }
    button {
      width: 200px;
      padding: 1.1rem;
      margin: 0.5rem 0.5rem 0.5rem 0;
      border-radius: 5px;
      border: 0;
      font-size: 1.2rem;
      background-color: $sky;
      color: $white;
      cursor: pointer;
      &:hover {
        background-color: darken($sky, 10%);
      }
      &.disabled {
        cursor: not-allowed;
        background-color: #b3b3b3c7;
        color: $black;
        &:hover {
          background-color: #b3b3b3c7;
        }
      }
    }
    i {
      margin-top: 1rem;
      width: 100%;
      text-align: right;
      font-style: normal;
      b {
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  header {
    flex-direction: column;
    .Actions {
      text-align: center;
      flex: 0;
      padding: 1rem 0;
    }
    .MainMenu {
      ul {
        padding: 0;
      }
    }
  }
  .Banner {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem 0;
    .Banner__slogan {
      flex: 0 0;
      display: flex;
      h1, b, i {
        margin: 0;
        text-align: center;
      }
      h1 {
        font-size: 2rem;
      }
      button {
        margin: 1rem auto;
      }
    }
    .Banner__image {
      max-width: 700px;
      padding-bottom: 2rem;
    }
  }

  .Banner__footer {
    max-width: 700px;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 1rem;
        min-width: 320px;
      }
    }
  }

  .Content {
    padding: 2rem 0;
    ul.ContentList {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        max-width: 500px;
      }
    }
    h2 {
      line-height: normal;
    }
  }

  .Registration {
    form {
      width: 100%;
      max-width: 1440px;
      padding: 2rem 1rem 4rem 0;
    }
  }

  .Content {
    .SideImage {
      display: flex;
      flex-direction: column-reverse;
      text-align: center;
      .ImageSide {
        flex: 0 0 200px; 
        padding: 0 20%;
        margin: 2rem 0;
      }
      .TextSide {
        flex: 2 1;
        p {
          line-height: 1.4em;
        }
      }
    }
  }

  .OurMission {
    padding-top: 50px;
    h1, h2 {
      text-align: center;
    }
    h1 {
      font-size: 2.5em;
    }
  }
}

.Address {
  margin: auto;
  max-width: 600px;
  padding: 1.5rem;
  text-align: center;
  font-weight: 500;
}

.SupportedBy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  img {
    max-height: 140px;
  }
}

.Footer {
  text-align: center;
  padding: 1.5rem;
}