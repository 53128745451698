//

.antdContainer {

    @import '~antd/dist/antd.less';
    // @import  '../../../node_modules/antd/lib/style/default.less';
    //@import  '../../../node_modules/antd/lib/style/components.less';
    // @import  url('../../../node_modules/antd/dist/antd.less');




}