.TabbedSection {
    width: 100%;
    h1 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    &__tabs {
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        &::-webkit-scrollbar { /* WebKit */
            width: 0;
            height: 0;
        }
        margin-bottom: 1.2rem;
        &:after {
            content: '';
            position: absolute;
            left: 0; right: 0;
            top: 0; bottom: 0;
            pointer-events: none;
            background-image:
              linear-gradient(to right, #fff, rgba(0,128,128,0) 80px),
              linear-gradient(to left , #fff, rgba(0,128,128,0) 80px);
          }
    }
    &__tabsContainer {
        width: 100%;
        min-width: 800px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-right: 1.5rem;
    }
    .TabbedSectionButton  {
        margin: 0.2rem;
        font-size: 1rem;
        min-width: 150px;
        text-align: center;
        border: none;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        &__basetext {
            font-weight: bold;
            visibility: hidden;
        }
        &__text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        &___underline {
            display: none;
            height: 10px;
            margin: .3rem auto;
        }
        &--selected {
            img {
                display: block;
            }
            .TabbedSectionButton__text {
                font-weight: bold;
            }
        }
    }
    .TabbedSectionContent {
        padding-left: 2rem;
        padding-right: 2rem;
        ul {
            flex-direction: column;
            li {
                padding: 0 5rem;
                box-sizing: border-box;
                h4 {
                    margin-bottom: 0.7rem;
                    font-size: 1.1rem;
                }
                text-align: left;
                p {
                    text-align: left;
                }
            }
        }
    }
}