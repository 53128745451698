@import '../../cssreset.scss';
@import '../../colors.scss';


.forgotPaswordContainer {

    .ant-card-body {
        padding:0,
        
    }


}

