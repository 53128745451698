@import '../../cssreset.scss';
@import '../../colors.scss';



.challengeContainer {

    .ant-card{
        border-radius: 12px;
    }


    .ant-layout-header {
        background-color: $white;
        height: auto;
        padding-top: 14px;
        padding-bottom: 14px;
    }

    padding-bottom: 36px;

    .desktopDescription {
        padding-top: 36px;
        text-align: center;
        padding-right: 5em;
        padding-left: 5em;


        .subtitleText {
            font-size: 1.5rem;
            margin-bottom: 3em;
        }
    }

    .desktopCoverDisplay {
        padding-left: 5em;
        padding-right: 5em;
        background-color: $white;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .coverImageDesktop {
        border-radius: 12px;
        //margin-top: 24px;
    }

    .challengeTitle {
        margin-top: 16px;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .subtitle {
        font-size: 1rem;
        font-weight: 700;
    }

    .subtitleText {
        font-size: 1rem;
        font-weight: 700;
    }

    .paragraph {
        font-size: 1rem;
        color: $grey2;
        line-height: 1.6em;
    }

    .infoContent {
        margin-right: 18px;
        margin-left: 18px;
    }

    // start theme styles
    .themeContainer {
        margin-top: 16px;
        margin-bottom: 24px;

        .subtitle {
            margin-bottom: 16px;
        }

        .themeItem {
            border-radius: 24px;
            padding: 4px;
            margin-right: 12px;
            padding-right: 8px;
            padding-left: 8px;
            border-style: solid;
            border-width: 1px;
            border-color: $grey2;
            font-size: 0.8rem;
            color: $grey2;
        }
    }

    // end theme styles

    .infoContainer {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        .infoItem {
            display: flex;
            flex-direction: row;
            align-items: baseline;

            p {
                font-size: 1rem;
                color: $grey2;
                line-height: 1.6em;
            }

            .icon {
                height: 16px;
                width: 16px;
            }
        }
    }

    .statsContainerDesktop {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        //padding-left:5em;
        padding: 96px;

        .seperator {
            border-left-width: 1px;
            border-left-style: solid;
            border-left-color: #eeeeee;
            margin-right: 40px;
            // height: '70%';
        }

        h2 {
            font-size: 1rem;
            font-weight: 700;
            padding-top: 14px;
        }

        p {
            padding-top: 8px;
            font-size: 1.2em;
            color: $grey2;
            line-height: 1.4em;
        }

        .statsItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 40px;



        }

        .skillIcon {
            height: 6em;
            width: 6em;
        }

        .enrolledIcon {
            height: 6em;
            width: 7em;
        }

        .facilitationIcon {
            //margin-top: 4px;
            height: 5.7em;
            width: 5.7em;
        }
    }

    .statsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        // border-width: 1px;
        // border-style: solid;
        // border-color: red;

        .seperator {
            border-left-width: 1px;
            border-left-style: solid;
            border-left-color: #eeeeee;
            // height: '70%';
        }

        h2 {
            font-size: 0.8rem;
            font-weight: 700;
        }

        p {
            font-size: 0.8rem;
            color: $grey2;
            line-height: 1.4em;
        }

        .statsItem {
            display: flex;
            flex-direction: column;
            align-items: center;



        }


        .skillIcon {
            height: 24px;
            width: 24px;
        }

        .enrolledIcon {
            height: 24px;
            width: 30px;
        }

        .facilitationIcon {
            //margin-top: 4px;
            height: 20px;
            width: 20px;
        }

    }

    .skillsContainer {
        margin-bottom: 16px;

        .itemsContainer {
            display: flex;

            .skillItem {
                display: flex;
                flex-direction: row;

                align-items: center;

                .skillItemText {
                    //margin-right: 14px;
                    font-size: 1rem;
                    color: $grey2;
                    line-height: 1.6em;
                }

                .dot {
                    width: 4px;
                    height: 4px;
                    margin-left: 12px;
                    margin-right: 12px;
                    background-color: $grey2;
                    color: $grey2;
                    border-radius: 4px;
                }

            }
        }
    }

    .stepsContainerDesktop {
        margin-left: 5em;
        margin-right: 5em;
        padding-top: 36px;
        margin-bottom: 24px;

        .subtitleText {
            font-size: 24px;
            text-align: center;
            margin-bottom:3em;
        }

        .stepsList {

            .listItem {

                display: flex;
                flex-direction: row;
                border-radius: 12px;
                background-color: #fff8ff;
                //background-color: #e220e2;
                padding: 12px;
                margin-bottom: 16px;
                align-items: baseline;

                .paragraph {
                    padding-left: 8px;
                    padding-right: 12px;
                }

                .stepDot {
                    min-height: 16px;
                    max-height: 16px;
                    max-width: 16px;
                    min-width: 16px;
                    align-self: baseline;
                }
            }
        }
    }

    // start steps container mobile
    .stepsContainer {

        text-align: left;
        color: $black;
        margin-bottom: 24px;

        .subtitleText {
            margin-bottom: 16px;
        }

        .stepsList {

            .listItem {

                display: flex;
                flex-direction: row;
                border-radius: 12px;
                background-color: #fff8ff;
                //background-color: #e220e2;
                padding: 12px;
                margin-bottom: 16px;
                align-items: baseline;

                .paragraph {
                    padding-left: 8px;
                    padding-right: 12px;
                }

                .stepDot {
                    min-height: 16px;
                    max-height: 16px;
                    max-width: 16px;
                    min-width: 16px;
                    align-self: baseline;
                }
            }
        }
    }

    // end steps container

    .resourcesContainer {
        margin-top: 16px;

        .resourceTitleDesktop {
            text-align: center;
        }

        .subtitleText {
            font-size: 1.5rem;
        }

        .subtitle {
            margin-bottom: 16px;
        }

        .resourceItems {
            .resourceItemDesktop {
                padding-left: 5em;
                padding-right: 5em;

                .youtubeDesktop {
                    text-align: center;

                    // background-color: green;
                    p {
                        margin-top: 8px;
                    }

                }

                .youtube {
                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                        margin-top: 8px;
                    }
                }

                .file {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;

                    .item {
                        display: flex;
                        align-items: baseline;
                    }

                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                        margin-left: 16px;
                    }

                    .iconContainerDesktop {
                        border-radius: 40px;
                        padding: 8px;
                        background-color: #FB7D7D;


                        .icon {
                            height: 32px;
                            width: 38px;
                        }
                    }

                    .iconContainerMobile {
                        border-radius: 24px;
                        padding: 4px;
                        background-color: #FB7D7D;

                        .icon {
                            height: 16px;
                            width: 20px;
                        }
                    }

                    .downloadIcon {
                        height: 24px;
                        width: 24px;
                    }

                }

                .link {
                    display: flex;
                    align-items: baseline;
                    //justify-content: space-between;

                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                        margin-left: 16px;
                    }

                    .iconContainerDesktop {
                        border-radius: 40px;
                        padding: 8px;
                        background-color: #FB7D7D;

                        .icon {
                            height: 32px;
                            width: 38px;
                        }
                    }

                    .iconContainerMobile {
                        border-radius: 24px;
                        padding: 4px;
                        background-color: #FB7D7D;

                        .icon {
                            height: 16px;
                            width: 20px;
                        }
                    }


                    .downloadIcon {
                        height: 24px;
                        width: 24px;
                    }
                }

                .image {
                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                    }
                }

                .imageDesktop {
                    padding-left: 5em;
                    padding-right: 5em;

                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                    }
                }

                .desktopImage {
                    max-width: 1200px;
                    //text-align: center;

                    background-color: #FB7D7D;
                }

            }

            .resourceItem {

                .youtubeDesktop {
                    text-align: center;

                    // background-color: green;
                    p {
                        margin-top: 8px;
                    }
                }

                .youtube {
                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                        margin-top: 8px;
                    }
                }

                .file {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;

                    .item {
                        display: flex;
                        align-items: baseline;
                    }

                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                        margin-left: 16px;
                    }

                    .iconContainerDesktop {
                        border-radius: 40px;
                        padding: 8px;
                        background-color: #FB7D7D;


                        .icon {
                            height: 32px;
                            width: 38px;
                        }
                    }

                    .iconContainerMobile {
                        border-radius: 24px;
                        padding: 4px;
                        background-color: #FB7D7D;

                        .icon {
                            height: 16px;
                            width: 20px;
                        }
                    }

                    .downloadIcon {
                        height: 24px;
                        width: 24px;
                    }

                }

                .link {
                    display: flex;
                    align-items: baseline;
                    //justify-content: space-between;

                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                        margin-left: 16px;
                    }

                    .iconContainerDesktop {
                        border-radius: 40px;
                        padding: 8px;
                        background-color: #FB7D7D;

                        .icon {
                            height: 32px;
                            width: 38px;
                        }
                    }

                    .iconContainerMobile {
                        border-radius: 24px;
                        padding: 4px;
                        background-color: #FB7D7D;

                        .icon {
                            height: 16px;
                            width: 20px;
                        }
                    }


                    .downloadIcon {
                        height: 24px;
                        width: 24px;
                    }
                }

                .image {
                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                        margin-top: 8px;
                    }
                }

                .imageDesktop {
                    padding-left: 5em;
                    padding-right: 5em;

                    p {
                        font-size: 1rem;
                        color: $grey2;
                        line-height: 1.6em;
                        margin-top: 8px;
                    }
                }

                .desktopImage {
                    max-width: 1200px;
                    //text-align: center;

                    background-color: #FB7D7D;
                }


            }

        }

    }

    // end resource style block

    .creatorContainer {

        .creatorDesktop {



            .subtitleText {
                font-size: 1.5rem;
                margin-top: 4em;
                margin-bottom: 2em;
            }


            .person {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .space-right {
                    flex-grow: 1;
                }

                .content {
                    flex-grow: 2;
                    flex-direction: column;
                    padding: 8px;
                    display: flex;
                    align-items: center;

                    p {
                        //margin-left: 16px;
                        padding-top: 1em;
                        font-size: 1.5rem;
                        // color: $grey2;
                        line-height: 1em;
                        font-weight: 400;
                    }

                    .palceholder {

                        border-radius: 14em;
                        height: 8em;
                        width: 8em;
                        background-color: $grey2;
                        display: flex;
                        flex-direction: 'column';
                        justify-content: space-around;
                        align-items: flex-end;

                        .initials {
                            color: #fff;
                            font-size: 26px;
                            font-weight: 700;
                            // align-self: flex-end;
                            //justify-self: center;
                        }

                    }
                }
            }

            .club {

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .content {
                    flex-grow: 2;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $grey3;
                    border-radius: 12px;
                    padding: 24px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                   

                    p {
                        //margin-left: 12px;
                        font-size: 1rem;
                        // color: $grey2;
                        line-height: 1em;
                        font-weight: 400;
                        align-self: center;
                        margin-bottom: 0px;
                    }
                }

                .space-right {
                    flex-grow: 1;
                }


            }

            .text {

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .content {
                    flex-grow: 2;
                    border-style: solid;
                    border-width: 1px;
                    border-color: #bdbdbd;
                    border-radius: 12px;
                    padding: 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-top: 24px;
                    padding-bottom: 24px;
                    //justify-content: center;

                    p {
                        margin-left: 16px;
                        font-size: 1rem;
                        // color: $grey2;
                        line-height: 1em;
                        font-weight: 400;
                        align-self: center;
                        margin-bottom: 0px;
                    }
                }

                .space-right {
                    flex-grow: 1;
                }


            }

            .organisation {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .content {
                    flex-grow: 2;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $grey3;
                    border-radius: 12px;
                    padding: 24px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-top: 24px;
                    padding-bottom: 24px;
                    //justify-content: center;

                    p {
                        //margin-left: 16px;
                        font-size: 1rem;
                        // color: $grey2;
                        line-height: 1em;
                        font-weight: 400;
                        align-self: center;
                        margin-bottom: 0px;
                    }
                }

                .space-right {
                    flex-grow: 1;
                }
            }

        }

        .creator {

            .subtitleText{
                margin-bottom:1.5em;
            }

            .person {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex: 1;

                .space-right {
                    flex-grow: 1;
                }

                .content {
                    flex-grow: 2;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $grey3;
                    border-radius: 12px;
                    padding: 8px;
                    display: flex;
                    align-items: baseline;

                    p {
                        //margin-left: 16px;
                        font-size: 1rem;
                        // color: $grey2;
                        line-height: 1em;
                        font-weight: 400;
                    }

                    .palceholder {

                        border-radius: 120px;
                        height: 60px;
                        width: 60px;
                        background-color: $grey2;
                        display: flex;
                        flex-direction: 'column';
                        justify-content: space-around;
                        align-items: flex-end;

                        .initials {
                            color: #fff;
                            font-size: 26px;
                            font-weight: 700;
                            // align-self: flex-end;
                            //justify-self: center;
                        }

                    }
                }
            }

            .club {

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .content {
                    flex-grow: 2;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $grey3;
                    border-radius: 12px;
                    padding: 24px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-top: 24px;
                    padding-bottom: 24px;
                    //justify-content: center;

                    p {
                        //margin-left: 16px;
                        font-size: 1rem;
                        // color: $grey2;
                        line-height: 1em;
                        font-weight: 400;
                        align-self: center;
                        margin-bottom: 0px;
                    }
                }

                .space-right {
                    flex-grow: 1;
                }


            }

            .text {

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .content {
                    flex-grow: 2;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $grey3;
                    border-radius: 12px;
                    padding: 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-top: 24px;
                    padding-bottom: 24px;
                    //justify-content: center;

                    p {
                        margin-left: 16px;
                        font-size: 1rem;
                        // color: $grey2;
                        line-height: 1em;
                        font-weight: 400;
                        align-self: center;
                        margin-bottom: 0px;
                    }
                }

                .space-right {
                    flex-grow: 1;
                }


            }

            .organisation {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .content {
                    flex-grow: 2;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $grey3;
                    border-radius: 12px;
                    padding: 24px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-top: 24px;
                    padding-bottom: 24px;
                    //justify-content: center;

                    p {
                        margin-left: 16px;
                        font-size: 1rem;
                        // color: $grey2;
                        line-height: 1em;
                        font-weight: 400;
                        align-self: center;
                        margin-bottom: 0px;
                    }
                }

                .space-right {
                    flex-grow: 1;
                }
            }

        }
        
        .poweredByDesktop{
            padding-top:36px;
            padding-bottom:24px;
            .subtitleText {
                text-align: center;
                font-size: 24px;
                margin-bottom:3em;
            }
        }

        .poweredBy {
            margin-top: 16px;

            .item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .content {
                    flex-grow: 4;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $grey3;
                    border-radius: 12px;
                    padding: 8px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: baseline;

                }

                .space-right {
                    flex-grow: 1;
                    // border-style: solid;
                    // border-width: 1px;
                    // border-color: #771b1b;
                }

            }
        }
    }

}

// step background '#fff8ff'
// stepDot oneAppPurplePrimary.one300: '#b668b3',