@import '../../colors.scss';

.SiteNav {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    flex-wrap: wrap;
}

.SiteNavSection {
    flex: 1 0 200px;
    padding: .5rem 0;
    button {
        display: block;
        width: 140px;
        padding: 0.7rem 1rem;
        margin: 0.5rem 0.5rem 0.5rem 0;
        border-radius: 5px;
        border: 0;
        font-size: 0.8rem;
        font-weight: 600;
        background-color: $sky;
        color: $white;
        cursor: pointer;
        &:hover {
          background-color: darken($sky, 10%);
        }
    }
}

.SiteNavLogo {
    display: block;
    width: 3.125rem;
    padding: .5rem 0;
}

.SiteNavSection__heading {
    font-weight: 700;
    padding: .5rem 0;
}

.SiteNavSection__list {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.SiteNavLink {
    text-transform: capitalize;
}