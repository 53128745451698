$onepurple: #4F166D;
$pink: #D663D7;
$teal: #1DD4B7;
$green: #35AB71;
$navy: #2B1987;
$sky: #2A71D1;
$red: #D05355;
$yellow: #F8BD39;
$black: #424242;
$white: #fff;
$grey2: #717171;
$grey3: #f0f0f0;